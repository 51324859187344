import { Toast, Warning, useToasts } from "@flash-tecnologia/hros-web-ui-v2";
import { useCallback, useEffect } from "react";

export const ToastRender = () => {
  const { showToast } = useToasts();

  const showToastHandler = useCallback(
    ({ detail }: CustomEvent) => {
      const {
        variant = "toast",
        type = "warning",
        content,
        title,
        description = null,
      } = detail;
      showToast("", {
        content: (key) => {
          return (
            <div>
              {variant === "toast" ? (
                <Toast
                  id={key}
                  type={type}
                  title={content || title}
                  description={description}
                />
              ) : (
                <Warning
                  id={key}
                  type={type}
                  title={content || title}
                  description={description}
                />
              )}
            </div>
          );
        },
      });
    },
    [showToast]
  );

  useEffect(() => {
    window.addEventListener("showToast", showToastHandler);

    return () => {
      window.removeEventListener("showToast", showToastHandler);
    };
  }, []);

  return null;
};
