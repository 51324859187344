import { ThemeProvider, ToastProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { ToastRender } from "./toast-render";

export default function Root() {
  return (
    <ThemeProvider>
      <ToastProvider
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        maxSnack={3}
      >
        <ToastRender />
      </ToastProvider>
    </ThemeProvider>
  );
}
